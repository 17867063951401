import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { Auth0Provider } from './react-auth0-wrapper'

const onRedirectCallback = (appState: any) => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl ? appState.targetUrl : window.location.pathname,
  )
  /* eslint-disable-next-line no-self-assign */
  // window.location.pathname = window.location.pathname
}

ReactDOM.render(
  <Auth0Provider
    domain={'leonti.au.auth0.com'}
    client_id={'N4KkJDZeKstdQo1zkxlXKPpkuDEbNhnc'}
    audience={'trips-backend'}
    scope={'openid email profile'}
    redirect_uri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
  >
    <App />
  </Auth0Provider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
