import React from 'react'
import { Email, Trip, CarRental, Accommodation, Transport, Activity } from './Api'
import { List, ListItem, ListItemIcon, ListItemText, Card, CardContent, CardHeader } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'

interface Props {
  tripId: string
  trip: Trip
  emails: Email[]
  onEmailSelected: (emailId: string) => void
  selectedEmailId?: string
}

const EmailList: React.FC<Props> = ({ tripId, trip, emails, onEmailSelected, selectedEmailId }) => {
  interface EmailItems {
    accommodations: Accommodation[]
    carRentals: CarRental[]
    transport: Transport[]
    activities: Activity[]
  }

  const emailItems: (emailId: string) => EmailItems = emailId => ({
    accommodations: trip.accommodations.filter(i => i.emails.includes(emailId)),
    carRentals: trip.carRentals.filter(i => i.emails.includes(emailId)),
    transport: trip.transport.filter(i => i.emails.includes(emailId)),
    activities: trip.activities.filter(i => i.emails.includes(emailId)),
  })

  const isEmailProcessed = (items: EmailItems) =>
    items.accommodations.length > 0 ||
    items.activities.length > 0 ||
    items.transport.length > 0 ||
    items.carRentals.length > 0

  return (
    <Card>
      <CardHeader
        title={'Trip emails'}
        titleTypographyProps={{
          variant: 'h6',
        }}
      />
      <CardContent>
        <List>
          {emails.map((email, i) => {
            const items = emailItems(email.id)
            const emailIcon = isEmailProcessed(items) ? <CheckIcon /> : <span />

            return (
              <ListItem
                button
                key={i}
                onClick={() => onEmailSelected(email.id)}
                selected={selectedEmailId === email.id}
              >
                <ListItemIcon>{emailIcon}</ListItemIcon>
                <ListItemText primary={email.subject} />
              </ListItem>
            )
          })}
        </List>
        <div>
          Forward emails to <strong>{tripId}@avotrips.com</strong> to add them to this trip
        </div>
      </CardContent>
    </Card>
  )
}

export default EmailList
