import React from 'react'
import DateTimePicker from '../DateTimePicker'
import { CarRental } from '../Api'
import { LocalDate, LocalTime, LocalDateTime, DateTimeFormatter } from 'js-joda'
import { TextField, Grid } from '@material-ui/core'
import uuid from 'uuid/v4'

const format: (dateTime: LocalDateTime) => string = dateTime =>
  dateTime.format(DateTimeFormatter.ofPattern("yyyy-MM-dd'T'HH:mm"))

export interface State {
  id: string
  from: string
  to: string
  start: LocalDateTime
  end: LocalDateTime
  minDate: LocalDate
  maxDate: LocalDate
  emails: string[]
}
export const initialState: (minDate: LocalDate, maxDate: LocalDate, carRental?: CarRental) => State = (
  minDate,
  maxDate,
  carRental,
) => ({
  id: carRental ? carRental.id : uuid(),
  from: carRental ? carRental.from.name : '',
  to: carRental ? carRental.to.name : '',
  start: carRental ? LocalDateTime.parse(carRental.from.datetime) : LocalDateTime.of(minDate, LocalTime.of(0, 0)),
  end: carRental ? LocalDateTime.parse(carRental.to.datetime) : LocalDateTime.of(maxDate, LocalTime.of(0, 0)),
  minDate,
  maxDate,
  emails: carRental ? carRental.emails : [],
})

export const toCarRental: (state: State) => CarRental = state => ({
  id: state.id,
  from: {
    name: state.from,
    datetime: format(state.start),
  },
  to: {
    name: state.to,
    datetime: format(state.end),
  },
  emails: state.emails,
})

export type Action =
  | {
      type: 'set_from'
      from: string
    }
  | {
      type: 'set_to'
      to: string
    }
  | {
      type: 'set_from_datetime'
      dateTime: LocalDateTime
    }
  | {
      type: 'set_to_datetime'
      dateTime: LocalDateTime
    }
  | {
      type: 'reset'
    }

export const resetAction: Action = {
  type: 'reset',
}

export function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'set_from':
      return { ...state, from: action.from }
    case 'set_to':
      return { ...state, to: action.to }
    case 'set_from_datetime':
      return { ...state, start: action.dateTime }
    case 'set_to_datetime':
      return { ...state, end: action.dateTime }
    case 'reset':
      return initialState(state.minDate, state.maxDate)
    default:
      throw new Error()
  }
}

export const CarRentalForm: React.FC<{
  state: State
  dispatch: React.Dispatch<Action>
}> = ({ state, dispatch }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <TextField
          label="Pick Up"
          variant="outlined"
          fullWidth
          value={state.from}
          onChange={e => dispatch({ type: 'set_from', from: e.target.value })}
        />
      </Grid>
      <Grid item xs={6}>
        <DateTimePicker
          min={state.minDate}
          max={state.maxDate}
          dateTime={state.start}
          onChange={dateTime => dispatch({ type: 'set_from_datetime', dateTime })}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Drop Off"
          variant="outlined"
          fullWidth
          value={state.to}
          onChange={e => dispatch({ type: 'set_to', to: e.target.value })}
        />
      </Grid>
      <Grid item xs={6}>
        <DateTimePicker
          min={state.minDate}
          max={state.maxDate}
          dateTime={state.end}
          onChange={dateTime => dispatch({ type: 'set_to_datetime', dateTime })}
        />
      </Grid>
    </Grid>
  )
}
