import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Container, Grid, Paper, Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  main: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  container: {
    padding: theme.spacing(3),
    textAlign: 'center',
  },
}))

const LoggedOutView: React.FC = () => {
  const classes = useStyles()

  return (
    <Container className={classes.main} maxWidth="xl">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.container}>
            <Typography variant="h5">Please log in to start using the app</Typography>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}

export default LoggedOutView
