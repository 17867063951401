import React from 'react'
import DateTimePicker from '../DateTimePicker'
import { Transport, TransportType } from '../Api'
import { LocalDate, LocalTime, LocalDateTime, DateTimeFormatter } from 'js-joda'
import { TextField, Grid, Typography } from '@material-ui/core'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import uuid from 'uuid/v4'

const format: (dateTime: LocalDateTime) => string = dateTime =>
  dateTime.format(DateTimeFormatter.ofPattern("yyyy-MM-dd'T'HH:mm"))

export interface State {
  transportType: TransportType
  id: string
  from: string
  to: string
  fromDateTime: LocalDateTime
  toDateTime: LocalDateTime
  returnId: string
  returnFromDateTime: LocalDateTime
  returnToDateTime: LocalDateTime
  returnTransport: boolean
  connectingId?: string
  minDate: LocalDate
  maxDate: LocalDate
  emails: string[]
  returnEmails: string[]
}

export const initialState: (minDate: LocalDate, maxDate: LocalDate, ts?: Transport[]) => State = (
  minDate,
  maxDate,
  ts,
) => ({
  transportType: ts && ts.length > 0 ? ts[0].type : 'flight',
  id: ts && ts.length > 0 ? ts[0].id : uuid(),
  from: ts && ts.length > 0 ? ts[0].from.name : '',
  to: ts && ts.length > 0 ? ts[0].to.name : '',
  fromDateTime:
    ts && ts.length > 0 ? LocalDateTime.parse(ts[0].from.datetime) : LocalDateTime.of(minDate, LocalTime.of(0, 0)),
  toDateTime:
    ts && ts.length > 0 ? LocalDateTime.parse(ts[0].to.datetime) : LocalDateTime.of(minDate, LocalTime.of(0, 0)),
  returnId: ts && ts.length > 1 ? ts[1].id : uuid(),
  returnFromDateTime:
    ts && ts.length > 1 ? LocalDateTime.parse(ts[1].from.datetime) : LocalDateTime.of(maxDate, LocalTime.of(0, 0)),
  returnToDateTime:
    ts && ts.length > 1 ? LocalDateTime.parse(ts[1].to.datetime) : LocalDateTime.of(maxDate, LocalTime.of(0, 0)),
  returnTransport: ts ? ts.length > 1 : true,
  connectingId: ts && ts.length > 0 ? ts[0].return : uuid(),
  minDate,
  maxDate,
  emails: ts && ts.length > 0 ? ts[0].emails : [],
  returnEmails: ts && ts.length > 1 ? ts[1].emails : [],
})

export const toTransport: (state: State) => Transport[] = state => {
  const returnId = state.returnTransport ? state.connectingId : undefined

  return [
    {
      id: state.id,
      return: returnId,
      type: state.transportType,
      from: {
        name: state.from,
        datetime: format(state.fromDateTime),
      },
      to: {
        name: state.to,
        datetime: format(state.toDateTime),
      },
      emails: state.emails,
    },
  ].concat(
    state.returnTransport
      ? [
          {
            id: state.returnId,
            return: returnId,
            type: state.transportType,
            from: {
              name: state.to,
              datetime: format(state.returnFromDateTime),
            },
            to: {
              name: state.from,
              datetime: format(state.returnToDateTime),
            },
            emails: state.returnEmails,
          },
        ]
      : [],
  )
}

export type Action =
  | {
      type: 'set_transport_type'
      transportType: TransportType
    }
  | {
      type: 'set_from'
      from: string
    }
  | {
      type: 'set_to'
      to: string
    }
  | {
      type: 'set_from_datetime'
      dateTime: LocalDateTime
    }
  | {
      type: 'set_to_datetime'
      dateTime: LocalDateTime
    }
  | {
      type: 'set_return_from_datetime'
      dateTime: LocalDateTime
    }
  | {
      type: 'set_return_to_datetime'
      dateTime: LocalDateTime
    }
  | {
      type: 'toggle_return'
    }
  | {
      type: 'reset'
    }

export const resetAction: Action = {
  type: 'reset',
}

export function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'set_transport_type':
      return { ...state, transportType: action.transportType }
    case 'set_from':
      return { ...state, from: action.from }
    case 'set_to':
      return { ...state, to: action.to }
    case 'set_from_datetime':
      return { ...state, fromDateTime: action.dateTime }
    case 'set_to_datetime':
      return { ...state, toDateTime: action.dateTime }
    case 'set_return_from_datetime':
      return { ...state, returnFromDateTime: action.dateTime }
    case 'set_return_to_datetime':
      return { ...state, returnToDateTime: action.dateTime }
    case 'toggle_return':
      return { ...state, returnTransport: !state.returnTransport }
    case 'reset':
      return initialState(state.minDate, state.maxDate)
    default:
      throw new Error()
  }
}

export const TransportForm: React.FC<{
  state: State
  dispatch: React.Dispatch<Action>
}> = ({ state, dispatch }) => {
  const returnView = state.returnTransport ? (
    <Grid container item spacing={3}>
      <Grid item xs={6}>
        <Typography variant="body1">{state.to}</Typography>
      </Grid>
      <Grid item xs={6}>
        <DateTimePicker
          min={state.minDate}
          max={state.maxDate}
          dateTime={state.returnFromDateTime}
          onChange={dateTime => dispatch({ type: 'set_return_from_datetime', dateTime })}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body1">{state.from}</Typography>
      </Grid>
      <Grid item xs={6}>
        <DateTimePicker
          min={state.minDate}
          max={state.maxDate}
          dateTime={state.returnToDateTime}
          onChange={dateTime => dispatch({ type: 'set_return_to_datetime', dateTime })}
        />
      </Grid>
    </Grid>
  ) : null

  function isTransportType(str: string): str is TransportType {
    return str === 'flight' || str === 'train' || str === 'bus' || str === 'car'
  }

  return (
    <Grid container item spacing={3}>
      <Grid item xs={6}>
        <Select
          value={state.transportType}
          onChange={e => {
            if (isTransportType(e.target.value as string)) {
              dispatch({ type: 'set_transport_type', transportType: e.target.value as TransportType })
            }
          }}
        >
          <MenuItem value="flight">Flight</MenuItem>
          <MenuItem value="train">Train</MenuItem>
          <MenuItem value="bus">Bus</MenuItem>
          <MenuItem value="car">Car</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={6}>
        <label>
          <input type="checkbox" onChange={() => dispatch({ type: 'toggle_return' })} checked={state.returnTransport} />
          2-way
        </label>
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="From"
          variant="outlined"
          fullWidth
          value={state.from}
          onChange={e => dispatch({ type: 'set_from', from: e.target.value })}
        />
      </Grid>
      <Grid item xs={6}>
        <DateTimePicker
          min={state.minDate}
          max={state.maxDate}
          dateTime={state.fromDateTime}
          onChange={dateTime => dispatch({ type: 'set_from_datetime', dateTime })}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="To"
          variant="outlined"
          fullWidth
          value={state.to}
          onChange={e => dispatch({ type: 'set_to', to: e.target.value })}
        />
      </Grid>
      <Grid item xs={6}>
        <DateTimePicker
          min={state.minDate}
          max={state.maxDate}
          dateTime={state.toDateTime}
          onChange={dateTime => dispatch({ type: 'set_to_datetime', dateTime })}
        />
      </Grid>
      {returnView}
    </Grid>
  )
}
