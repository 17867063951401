import React, { useState, useReducer } from 'react'
import { CarRental, Accommodation, Activity, Transport } from '../Api'
import * as TransportForm from './TransportForm'
import * as CarRentalForm from './CarRentalForm'
import * as AccommodationForm from './AccommodationForm'
import * as ActivityForm from './ActivityForm'
import { LocalDate } from 'js-joda'
import { Button, Grid } from '@material-ui/core'

type FormType = 'transport' | 'car-rental' | 'accommodation' | 'activity'

interface Props {
  emailId?: string
  onTransportAdded: (_: Transport[]) => void
  onCarRentalAdded: (_: CarRental) => void
  onAccommodationAdded: (_: Accommodation) => void
  onActivityAdded: (_: Activity) => void
  minDate: LocalDate
  maxDate: LocalDate
  hideActivity?: boolean
}

const FormSelector: React.FC<{
  onSelect: (formType: FormType) => void
  hideActivity: boolean
}> = ({ onSelect, hideActivity }) => {
  const onFormSelected = (formType: FormType) => () => {
    onSelect(formType)
  }

  const activityButton = !hideActivity ? <Button onClick={onFormSelected('activity')}>Add activity</Button> : null

  return (
    <div>
      <Button onClick={onFormSelected('car-rental')}>Add car rental</Button>
      <Button onClick={onFormSelected('transport')}>Add transport</Button>
      <Button onClick={onFormSelected('accommodation')}>Add accommodation</Button>
      {activityButton}
    </div>
  )
}

const TripForms: React.FC<Props> = ({
  emailId,
  onTransportAdded,
  onCarRentalAdded,
  onAccommodationAdded,
  onActivityAdded,
  minDate,
  maxDate,
  hideActivity = false,
}) => {
  const [transportFormState, transportFormDispatch] = useReducer(
    TransportForm.reducer,
    TransportForm.initialState(minDate, maxDate),
  )
  const [carFormState, carFormDispatch] = useReducer(
    CarRentalForm.reducer,
    CarRentalForm.initialState(minDate, maxDate),
  )
  const [accommodationFormState, accommodationFormDispatch] = useReducer(
    AccommodationForm.reducer,
    AccommodationForm.initialState(minDate, maxDate),
  )
  const [activityFormState, activityFormDispatch] = useReducer(
    ActivityForm.reducer,
    ActivityForm.initialState(minDate, maxDate),
  )

  const [form, setForm] = useState<FormType | undefined>()

  function getForm(formType: FormType) {
    switch (formType) {
      case 'transport':
        return <TransportForm.TransportForm state={transportFormState} dispatch={transportFormDispatch} />
      case 'car-rental':
        return <CarRentalForm.CarRentalForm state={carFormState} dispatch={carFormDispatch} />
      case 'accommodation':
        return (
          <AccommodationForm.AccommodationForm state={accommodationFormState} dispatch={accommodationFormDispatch} />
        )
      case 'activity':
        return <ActivityForm.ActivityForm state={activityFormState} dispatch={activityFormDispatch} showDate={true} />
    }
  }

  function onAdd(formType: FormType) {
    setForm(undefined)
    switch (formType) {
      case 'transport':
        transportFormDispatch(TransportForm.resetAction)
        return onTransportAdded(
          TransportForm.toTransport(transportFormState).map(transport => ({
            ...transport,
            emails: emailId ? [emailId] : [],
          })),
        )
      case 'car-rental':
        carFormDispatch(CarRentalForm.resetAction)
        return onCarRentalAdded({ ...CarRentalForm.toCarRental(carFormState), emails: emailId ? [emailId] : [] })
      case 'accommodation':
        accommodationFormDispatch(AccommodationForm.resetAction)
        return onAccommodationAdded({
          ...AccommodationForm.toAccommodation(accommodationFormState),
          emails: emailId ? [emailId] : [],
        })
      case 'activity':
        activityFormDispatch(ActivityForm.resetAction)
        return onActivityAdded({ ...ActivityForm.toActivity(activityFormState), emails: emailId ? [emailId] : [] })
    }
  }

  const formView = form ? (
    <Grid container spacing={3} direction="column" justify="flex-start" alignItems="stretch">
      <Grid item>{getForm(form)}</Grid>
      <Grid container item spacing={3}>
        <Grid item>
          <Button variant="contained" onClick={() => onAdd(form)}>
            Add
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={() => setForm(undefined)}>Cancel</Button>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <FormSelector onSelect={setForm} hideActivity={hideActivity} />
  )

  return formView
}

export default TripForms
