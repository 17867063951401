import React, { useState, useReducer } from 'react'
import { Activity, Email } from '../Api'
import TripItem from './TripItem'
import { LocalDate } from 'js-joda'
import { makeStyles } from '@material-ui/core/styles'
import { FaHiking } from 'react-icons/fa'
import * as ActivityForm from '../trip-forms/ActivityForm'
import { Button, Grid } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  icon: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
  },
}))

const ActivityItem: React.FC<{
  minDate: LocalDate
  maxDate: LocalDate
  activity: Activity
  onUpdate: (_: Activity) => void
  tripId: string
  emails: Email[]
}> = ({ minDate, maxDate, activity, onUpdate, tripId, emails }) => {
  const classes = useStyles()

  const [editing, setEditing] = useState<boolean>(false)
  const [activityFormState, activityFormDispatch] = useReducer(
    ActivityForm.reducer,
    ActivityForm.initialState(minDate, maxDate, activity),
  )

  const editView = editing ? (
    <>
      <Grid container spacing={3} direction="column" justify="flex-start" alignItems="stretch">
        <Grid item>
          <ActivityForm.ActivityForm state={activityFormState} dispatch={activityFormDispatch} showDate={true} />
        </Grid>
        <Grid container item spacing={3}>
          <Grid item>
            <Button variant="contained" onClick={() => onUpdate(ActivityForm.toActivity(activityFormState))}>
              Save
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={() => setEditing(false)}>Cancel</Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  ) : (
    undefined
  )

  return (
    <TripItem
      icon={<FaHiking className={classes.icon} />}
      label={`${activity.time ? activity.time : null} ${activity.name}`}
      secondaryText={activity.note}
      tripId={tripId}
      emails={emails}
      editable={true}
      onEditToggle={() => setEditing(!editing)}
      editView={editView}
    />
  )
}

export default ActivityItem
