import React, { useState } from 'react'
import { Email } from '../Api'
import EmailView from '../EmailView'
import { ListItem, ListItemIcon, IconButton, ListItemText, ListItemSecondaryAction } from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email'
import EditIcon from '@material-ui/icons/Edit'
import { makeStyles } from '@material-ui/core/styles'

// word-break: break-word;

const useStyles = makeStyles(theme => ({
  itemText: {
    wordBreak: 'break-word',
  },
}))

const TripItem: React.FC<{
  icon: React.ReactElement
  label: string
  secondaryText?: string
  tripId: string
  emails: Email[]
  editable?: boolean
  onEditToggle?: () => void // make required
  editView?: React.ReactElement
}> = ({ icon, label, secondaryText, tripId, emails, editable, onEditToggle, editView }) => {
  const classes = useStyles()
  const [selectedEmail, setSelectedEmail] = useState<Email | undefined>(undefined)

  const [emailsShown, setEmailsShown] = useState(false)
  const toggleEmails = () => setEmailsShown(!emailsShown)

  const secondary = secondaryText ? <span>{secondaryText}</span> : null

  return (
    <>
      <ListItem>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText className={classes.itemText} primary={label} secondary={secondary} />
        <ListItemSecondaryAction>
          <>
            {emails.length > 0 && (
              <IconButton edge="end" onClick={toggleEmails}>
                <EmailIcon />
              </IconButton>
            )}
            {editable && (
              <IconButton edge="end" onClick={onEditToggle}>
                <EditIcon />
              </IconButton>
            )}
          </>
        </ListItemSecondaryAction>
      </ListItem>{' '}
      {emailsShown && (
        <>
          {emails.flatMap((email, i) => [
            <ListItem
              button
              onClick={() => {
                if (selectedEmail && selectedEmail.id === email.id) {
                  setSelectedEmail(undefined)
                } else {
                  setSelectedEmail(email)
                }
              }}
              selected={false}
              key={i}
            >
              <ListItemText primary={email.subject} />
            </ListItem>,
            selectedEmail && selectedEmail.id === email.id ? (
              <ListItem key={`${i}-selected`}>
                <EmailView tripId={tripId} email={selectedEmail} setError={() => {}} />
              </ListItem>
            ) : null,
          ])}
        </>
      )}
      {editView}
    </>
  )
}

export default TripItem
