import React from 'react'
import { Activity } from '../Api'
import DateTimePicker from '../DateTimePicker'
import { TextField, Grid, Checkbox, FormControlLabel } from '@material-ui/core'
import { LocalDate, LocalTime, LocalDateTime, DateTimeFormatter } from 'js-joda'
import uuid from 'uuid/v4'

const format: (dateTime: LocalDateTime) => string = dateTime =>
  dateTime.format(DateTimeFormatter.ofPattern("yyyy-MM-dd'T'HH:mm"))

export type State = {
  id: string
  name: string
  timeKnown: boolean
  date: LocalDate
  note?: string
  dateTime: LocalDateTime
  minDate: LocalDate
  maxDate: LocalDate
  emails: string[]
}

export const initialStateWithDate: (
  date: LocalDate,
  minDate: LocalDate,
  maxDate: LocalDate,
  activity?: Activity,
) => State = (date, minDate, maxDate, activity) => ({
  id: activity ? activity.id : uuid(),
  name: activity ? activity.name : '',
  date: activity ? LocalDate.parse(activity.date) : date,
  timeKnown: activity ? !!activity.time : false,
  note: activity ? activity.note : undefined,
  dateTime: activity
    ? activity.time
      ? LocalDateTime.of(LocalDate.parse(activity.date), LocalTime.parse(activity.time))
      : LocalDateTime.of(LocalDate.parse(activity.date), LocalTime.of(0, 0))
    : LocalDateTime.of(minDate, LocalTime.of(0, 0)),
  minDate,
  maxDate,
  emails: activity ? activity.emails : [],
})

export const initialState = (minDate: LocalDate, maxDate: LocalDate, activity?: Activity) =>
  initialStateWithDate(minDate, minDate, maxDate, activity)

export const toActivity: (state: State) => Activity = state => ({
  id: state.id,
  name: state.name,
  note: state.note,
  emails: state.emails,
  date: state.dateTime.format(DateTimeFormatter.ofPattern('yyyy-MM-dd')),
  time: state.timeKnown ? state.dateTime.format(DateTimeFormatter.ofPattern('HH:mm')) : undefined,
})
export const toDate: (state: State) => string = state => format(state.dateTime)

export type Action =
  | {
      type: 'set_name'
      name: string
    }
  | {
      type: 'set_note'
      note: string
    }
  | {
      type: 'toggle_time'
    }
  | {
      type: 'set_date_time'
      dateTime: LocalDateTime
    }
  | {
      type: 'reset'
    }

export const resetAction: Action = {
  type: 'reset',
}

export function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'set_name':
      return { ...state, name: action.name }
    case 'set_note':
      return { ...state, note: action.note }
    case 'toggle_time':
      return { ...state, timeKnown: !state.timeKnown }
    case 'set_date_time':
      return {
        ...state,
        dateTime: action.dateTime,
      }
    case 'reset':
      return initialState(state.minDate, state.maxDate)
    default:
      throw new Error()
  }
}

export const ActivityForm: React.FC<{
  state: State
  dispatch: React.Dispatch<Action>
  showDate: boolean
}> = ({ state, dispatch, showDate = false }) => {
  const datePicker = showDate ? (
    <Grid item>
      <DateTimePicker
        min={state.minDate}
        max={state.maxDate}
        dateTime={state.dateTime}
        onChange={dateTime => dispatch({ type: 'set_date_time', dateTime })}
        showTime={state.timeKnown}
      />
    </Grid>
  ) : (
    <Grid item>
      <DateTimePicker
        dateTime={state.dateTime}
        onChange={dateTime => dispatch({ type: 'set_date_time', dateTime })}
        showDate={false}
        showTime={state.timeKnown}
      />
    </Grid>
  )

  return (
    <Grid container spacing={3} direction="column" justify="flex-start" alignItems="stretch">
      <Grid item>
        <TextField
          label="Activity name"
          variant="outlined"
          fullWidth
          value={state.name}
          onChange={e => dispatch({ type: 'set_name', name: e.target.value })}
        />
      </Grid>
      <Grid item>
        <TextField
          label="Note"
          multiline
          fullWidth
          rows="4"
          value={state.note}
          onChange={e => dispatch({ type: 'set_note', note: e.target.value })}
          variant="outlined"
        />
      </Grid>
      <Grid item>
        <FormControlLabel
          control={<Checkbox checked={state.timeKnown} onChange={() => dispatch({ type: 'toggle_time' })} />}
          label="Time"
        />
      </Grid>
      {datePicker}
    </Grid>
  )
}
