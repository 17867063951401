import React, { useState, useEffect, useCallback } from 'react'
import { Email } from './Api'
import { getAttachmentDlUrl, getEmailDlUrl } from './Api'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Grid, LinearProgress } from '@material-ui/core'
import { FaDownload } from 'react-icons/fa'
import { useAuth0 } from './react-auth0-wrapper'

interface Props {
  tripId: string
  email: Email
  setError: (e: string) => void
}

const useStyles = makeStyles(theme => ({
  iframe: {
    width: '100%',
    height: '600px',
    border: 'none',
    //    marginTop: theme.spacing(3),
    //    marginBottom: theme.spacing(3),
  },
  link: {
    cursor: 'pointer',
  },
  emailText: {
    wordBreak: 'break-word',
  },
}))

const EmailView: React.FC<Props> = ({ tripId, email, setError }) => {
  const classes = useStyles()
  const [emailUrl, setEmailUrl] = useState('')
  const [urlLoading, setUrlLoading] = useState(false)
  const { getAuthToken } = useAuth0()

  const viewEmail = useCallback(() => {
    setUrlLoading(true)
    getAuthToken()
      .then(t => getEmailDlUrl(t, tripId, email.id))
      .then(dlUrl => {
        setUrlLoading(false)
        setEmailUrl(dlUrl.url)
      })
      .catch(e => setError(`${e}`))

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [email.id, tripId])

  useEffect(() => {
    viewEmail()
  }, [viewEmail])

  const emailContent = urlLoading ? (
    <LinearProgress />
  ) : (
    <iframe title="email-view" className={classes.iframe} src={emailUrl} />
  )
  return (
    <Grid container item xs={12} spacing={3} direction="column" justify="flex-start" alignItems="stretch">
      <Grid item xs={12}>
        {email.attachments.map((a, ix) => (
          <div>
            <Typography
              key={ix}
              variant="h5"
              onClick={() => {
                setUrlLoading(true)
                getAuthToken()
                  .then(t => getAttachmentDlUrl(t, tripId, email.id, ix))
                  .then(dlUrl => {
                    setUrlLoading(false)
                    setEmailUrl(dlUrl.url)
                  })
                  .catch(e => setError(`${e}`))
              }}
              className={`${classes.link} ${classes.emailText}`}
            >
              Attachment #{ix} {a.filename}
            </Typography>
            <FaDownload
              onClick={() => {
                getAuthToken()
                  .then(t => getAttachmentDlUrl(t, tripId, email.id, ix))
                  .then(dlUrl => {
                    window.open(dlUrl.url, '_blank')
                  })
                  .catch(e => setError(`${e}`))
              }}
            />
          </div>
        ))}
      </Grid>
      <Grid item xs={12}>
        {emailContent}
      </Grid>
    </Grid>
  )
}

export default EmailView
