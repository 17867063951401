const baseUrl = 'https://api.avotrips.com'

async function http<T>(url: string, params: any): Promise<T> {
  const response = await fetch(url, params)

  if (response.status >= 400) {
    throw new Error(`Request failed with status ${response.status}`)
  }
  return await response.json()
}

export interface TripInfo {
  tripId: string
  name: string
}

export interface Activity {
  id: string
  name: string
  date: string
  time?: string
  note?: string
  emails: string[]
}

export type TransportType = 'flight' | 'train' | 'bus' | 'car'

export interface Transport {
  id: string
  return?: string
  type: TransportType
  from: {
    name: string
    datetime: string
  }
  to: {
    name: string
    datetime: string
  }
  emails: string[]
}

export interface CarRental {
  id: string
  from: {
    name: string
    datetime: string
  }
  to: {
    name: string
    datetime: string
  }
  emails: string[]
}

export interface Accommodation {
  id: string
  location: string
  start: string
  end: string
  emails: string[]
}

export interface Attachment {
  filename: string
  contentType: string
}

export interface Email {
  id: string
  subject: string
  attachments: Attachment[]
}

export interface Trip {
  startDate: string
  endDate: string
  name: string
  transport: Transport[]
  accommodations: Accommodation[]
  carRentals: CarRental[]
  activities: Activity[]
}

export interface TripCreated {
  tripId: string
}

export interface DlUrl {
  url: string
  expiresIn: number
}

export const getTrips = (authToken: string) =>
  http<TripInfo[]>(`${baseUrl}/trip`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  })

export const getTrip = (authToken: string, id: string) =>
  http<Trip>(`${baseUrl}/trip/${id}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  })

export const getTripEmails = (authToken: string, id: string) =>
  http<Email[]>(`${baseUrl}/trip/${id}/emails`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  })

export const getEmailDlUrl = (authToken: string, id: string, emailId: string) =>
  http<DlUrl>(`${baseUrl}/trip/${id}/email/${emailId}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  })

export const getAttachmentDlUrl = (authToken: string, id: string, emailId: string, attachmentIndex: number) =>
  http<DlUrl>(`${baseUrl}/trip/${id}/email/${emailId}/attachment/${attachmentIndex}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  })

export async function saveTrip(authToken: string, id: string, trip: Trip): Promise<{}> {
  const headers: any = {
    Authorization: `Bearer ${authToken}`,
  }
  const response = await fetch(`${baseUrl}/trip/${id}`, {
    method: 'PUT',
    headers,
    body: JSON.stringify(trip),
  })

  if (response.status >= 400) {
    throw new Error(`Request failed with status ${response.status}`)
  }
  return {}
}

export const createTrip = (authToken: string, trip: Trip) =>
  http<TripCreated>(`${baseUrl}/trip`, {
    method: 'POST',
    body: JSON.stringify(trip),
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  })

export type TripAction =
  | {
      type: 'add_transport'
      transport: Transport[]
    }
  | {
      type: 'transport_update'
      transport: Transport[]
    }
  | {
      type: 'add_car_rental'
      car: CarRental
    }
  | {
      type: 'car_rental_update'
      car: CarRental
    }
  | {
      type: 'add_accommodation'
      accommodation: Accommodation
    }
  | {
      type: 'accommodation_update'
      accommodation: Accommodation
    }
  | {
      type: 'add_day_activity'
      activity: Activity
    }
  | {
      type: 'day_activity_update'
      activity: Activity
    }
  | {
      type: 'item_email_link'
      itemId: string
      emailId: string
    }
  | {
      type: 'trip_loaded'
      trip: Trip
    }
