import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import './App.css'
import TripView from './TripView'
import Overview from './Overview'
import CreateTrip from './CreateTrip'
import CssBaseline from '@material-ui/core/CssBaseline'
import NavBar from './NavBar'
import LoggedOutView from './LoggedOutView'
import { useAuth0 } from './react-auth0-wrapper'
import { IconContext } from 'react-icons'

const App: React.FC = () => {
  const { isAuthenticated, loading } = useAuth0()

  const authView = isAuthenticated ? (
    <div>
      <Route exact path="/" component={Overview} />
      <Switch>
        <Route path="/trip/new" component={CreateTrip} />
        <Route path="/trip/:tripId" component={TripView} />
      </Switch>
    </div>
  ) : (
    <div>{!loading && <LoggedOutView />}</div>
  )

  return (
    <Router>
      <CssBaseline />
      <IconContext.Provider value={{ style: { verticalAlign: 'middle' } }}>
        <div className="App">
          <NavBar />
          {authView}
        </div>
      </IconContext.Provider>
    </Router>
  )
}

export default App
