import React from 'react'
import DateTimePicker from '../DateTimePicker'
import { Accommodation } from '../Api'
import { TextField, Typography, Grid } from '@material-ui/core'
import { LocalDate, LocalTime, LocalDateTime, DateTimeFormatter } from 'js-joda'
import uuid from 'uuid/v4'

const format: (dateTime: LocalDateTime) => string = dateTime =>
  dateTime.format(DateTimeFormatter.ofPattern("yyyy-MM-dd'T'HH:mm"))

export interface State {
  id: string
  location: string
  start: LocalDateTime
  end: LocalDateTime
  minDate: LocalDate
  maxDate: LocalDate
}

export const initialState: (minDate: LocalDate, maxDate: LocalDate, accommodation?: Accommodation) => State = (
  minDate,
  maxDate,
  acm,
) => ({
  id: acm ? acm.id : uuid(),
  location: acm ? acm.location : '',
  start: acm ? LocalDateTime.parse(acm.start) : LocalDateTime.of(minDate, LocalTime.of(0, 0)),
  end: acm ? LocalDateTime.parse(acm.end) : LocalDateTime.of(maxDate, LocalTime.of(0, 0)),
  minDate,
  maxDate,
})

export const toAccommodation: (state: State) => Accommodation = state => ({
  id: state.id,
  location: state.location,
  start: format(state.start),
  end: format(state.end),
  emails: [],
})

export type Action =
  | {
      type: 'set_location'
      location: string
    }
  | {
      type: 'set_from_datetime'
      dateTime: LocalDateTime
    }
  | {
      type: 'set_to_datetime'
      dateTime: LocalDateTime
    }
  | {
      type: 'reset'
    }

export const resetAction: Action = {
  type: 'reset',
}

export function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'set_location':
      return { ...state, location: action.location }
    case 'set_from_datetime':
      return { ...state, start: action.dateTime }
    case 'set_to_datetime':
      return { ...state, end: action.dateTime }
    case 'reset':
      return initialState(state.minDate, state.maxDate)
    default:
      throw new Error()
  }
}

export const AccommodationForm: React.FC<{
  state: State
  dispatch: React.Dispatch<Action>
}> = ({ state, dispatch }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          label="Property"
          variant="outlined"
          fullWidth
          value={state.location}
          onChange={e => dispatch({ type: 'set_location', location: e.target.value })}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body1">Check-in:</Typography>
      </Grid>
      <Grid item xs={6}>
        <DateTimePicker
          min={state.minDate}
          max={state.maxDate}
          dateTime={state.start}
          onChange={dateTime => dispatch({ type: 'set_from_datetime', dateTime })}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body1">Check-out:</Typography>
      </Grid>
      <Grid item xs={6}>
        <DateTimePicker
          min={state.minDate}
          max={state.maxDate}
          dateTime={state.end}
          onChange={dateTime => dispatch({ type: 'set_to_datetime', dateTime })}
        />
      </Grid>
    </Grid>
  )
}
