import React from 'react'
import { Email, Trip, CarRental, Accommodation, Activity } from './Api'
import { Transport } from './Api'
import { LocalDate } from 'js-joda'
import { Card, CardContent, Grid, CardHeader } from '@material-ui/core'
import TripForms from './trip-forms/TripForms'
import TripItemsList from './trip-items/TripItemsList'
import EmailView from './EmailView'

interface Props {
  tripId: string
  trip: Trip
  email: Email
  emails: Email[]
  setError: (e: string) => void
  onTransportAdded: (_: Transport[]) => void
  onCarRentalAdded: (_: CarRental) => void
  onAccommodationAdded: (_: Accommodation) => void
  onActivityAdded: (_: Activity) => void
  onItemLink: (itemId: string, emailId: string) => void
  minDate: LocalDate
  maxDate: LocalDate
}

const EmailWithForms: React.FC<Props> = ({
  tripId,
  trip,
  email,
  emails,
  setError,
  onTransportAdded,
  onCarRentalAdded,
  onAccommodationAdded,
  onActivityAdded,
  onItemLink,
  minDate,
  maxDate,
}) => {
  return (
    <Card>
      <CardHeader
        title={email.subject}
        titleTypographyProps={{
          variant: 'h5',
        }}
      />
      <CardContent>
        <Grid container spacing={3} direction="column" justify="flex-start" alignItems="stretch">
          <Grid item>
            <EmailView tripId={tripId} email={email} setError={setError} />
          </Grid>
          <Grid item>
            <TripForms
              emailId={email.id}
              onTransportAdded={onTransportAdded}
              onCarRentalAdded={onCarRentalAdded}
              onAccommodationAdded={onAccommodationAdded}
              onActivityAdded={onActivityAdded}
              minDate={minDate}
              maxDate={maxDate}
            />
          </Grid>
          <Grid item>
            <TripItemsList trip={trip} emails={emails} onItemClick={itemId => onItemLink(itemId, email.id)} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default EmailWithForms
