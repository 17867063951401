import React, { useEffect, useReducer } from 'react'
import { getTrips, TripInfo } from './Api'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Container, Card, CardContent, Typography, Grid, Fab, Paper, LinearProgress } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { useAuth0 } from './react-auth0-wrapper'

interface State {
  trips: TripInfo[]
  loading: boolean
  error?: string
}

const initialState: State = {
  loading: true,
  trips: [],
  error: undefined,
}

type Action =
  | {
      type: 'set_trips'
      trips: TripInfo[]
    }
  | {
      type: 'set_error'
      error: string
    }

const reducer: (state: State, action: Action) => State = (state, action) => {
  switch (action.type) {
    case 'set_trips':
      return { ...state, trips: action.trips, loading: false }
    case 'set_error':
      return { ...state, error: action.error, loading: false }
    default:
      throw new Error()
  }
}

const useStyles = makeStyles(theme => ({
  main: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  container: {
    padding: theme.spacing(3),
  },
  fab: {
    marginTop: theme.spacing(3),
  },
  cardContent: {
    padding: theme.spacing(2),
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
}))

const Overview: React.FC = () => {
  const classes = useStyles()
  const [state, dispatch] = useReducer(reducer, initialState)
  const { getAuthToken } = useAuth0()

  useEffect(() => {
    console.log('Loading overview')

    getAuthToken()
      .then(getTrips)
      .then(trips => {
        dispatch({ type: 'set_trips', trips })
      })
      .catch(e => {
        console.log(JSON.stringify(e))
        dispatch({ type: 'set_error', error: `${e}` })
      })
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])

  const error = state.error ? <div>{state.error}</div> : null

  const trips = (
    <Grid container spacing={3}>
      {state.trips.map((trip, id) => (
        <Grid item key={id}>
          <Card>
            <Link to={`/trip/${trip.tripId}`}>
              <CardContent className={classes.cardContent}>
                <Typography variant="h5" component="h2">
                  {trip.name}
                </Typography>
              </CardContent>
            </Link>
          </Card>
        </Grid>
      ))}
    </Grid>
  )

  const addButton = (
    <Grid container item xs={12} justify="flex-end">
      <Link to="/trip/new">
        <Fab className={classes.fab} color={'primary'}>
          <AddIcon />
        </Fab>
      </Link>
    </Grid>
  )

  return (
    <Container className={classes.main} maxWidth="xl">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.container}>
            {state.loading && <LinearProgress />}
            {error}
            {!state.loading && trips}
            {!state.loading && addButton}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Overview
