import React, { useState, useReducer } from 'react'
import { Accommodation, Email } from '../Api'
import TripItem from './TripItem'
import { makeStyles } from '@material-ui/core/styles'
import { FaBed } from 'react-icons/fa'
import { LocalDateTime, LocalDate, DateTimeFormatter } from 'js-joda'
import * as AccommodationForm from '../trip-forms/AccommodationForm'
import { Button, Grid } from '@material-ui/core'

const formatTime: (dateTime: LocalDateTime) => string = dateTime =>
  dateTime.format(DateTimeFormatter.ofPattern('HH:mm'))

const useStyles = makeStyles(theme => ({
  icon: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
  },
}))

const AccommodationItem: React.FC<{
  minDate: LocalDate
  maxDate: LocalDate
  type: 'check-in' | 'check-out' | 'sleeping'
  accommodation: Accommodation
  onUpdate: (_: Accommodation) => void
  tripId: string
  emails: Email[]
}> = ({ minDate, maxDate, type, accommodation, onUpdate, tripId, emails }) => {
  const classes = useStyles()

  const [editing, setEditing] = useState<boolean>(false)
  const [accommodationFormState, accommodationFormDispatch] = useReducer(
    AccommodationForm.reducer,
    AccommodationForm.initialState(minDate, maxDate, accommodation),
  )

  const editView = editing ? (
    <>
      <Grid container spacing={3} direction="column" justify="flex-start" alignItems="stretch">
        <Grid item>
          <AccommodationForm.AccommodationForm state={accommodationFormState} dispatch={accommodationFormDispatch} />
        </Grid>
        <Grid container item spacing={3}>
          <Grid item>
            <Button
              variant="contained"
              onClick={() => onUpdate(AccommodationForm.toAccommodation(accommodationFormState))}
            >
              Save
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={() => setEditing(false)}>Cancel</Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  ) : (
    undefined
  )

  const label = () => {
    switch (type) {
      case 'check-in':
        return `${formatTime(LocalDateTime.parse(accommodation.start))} ${accommodation.location}`
      case 'check-out':
        return `${formatTime(LocalDateTime.parse(accommodation.end))} ${accommodation.location}`
      default:
        return `${accommodation.location}`
    }
  }

  return (
    <TripItem
      icon={<FaBed className={classes.icon} />}
      label={label()}
      tripId={tripId}
      emails={emails}
      editable={true}
      onEditToggle={() => setEditing(!editing)}
      editView={editView}
    />
  )
}

export default AccommodationItem
