import React from 'react'
import { Trip, Email } from '../Api'
import { List, ListItem, Grid, ListItemText, Collapse } from '@material-ui/core'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  }),
)

type OpenCase = 'activities' | 'accommodation' | 'transport' | 'car-rental' | 'none'

const TripItemsList: React.FC<{
  trip: Trip
  emails: Email[]
  onItemClick: (itemId: string) => void
}> = ({ trip, emails, onItemClick }) => {
  const classes = useStyles()
  const [openCase, setOpenCase] = React.useState<OpenCase>('none')

  const toggle = (oc: OpenCase) => {
    if (oc === openCase) {
      setOpenCase('none')
    } else {
      setOpenCase(oc)
    }
  }

  const isOpen = (oc: OpenCase) => openCase === oc

  return (
    <Grid container spacing={3} direction="column" alignItems="stretch" justify="flex-start">
      <Grid item>
        <List>
          {trip.accommodations.length > 0 && (
            <div>
              <ListItem button onClick={() => toggle('accommodation')}>
                <ListItemText primary="Accommodation" />
                {isOpen('accommodation') ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={isOpen('accommodation')} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {trip.accommodations.map((a, i) => (
                    <ListItem key={i} button className={classes.nested} onClick={() => onItemClick(a.id)}>
                      <ListItemText primary={a.location} />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </div>
          )}

          {trip.activities.length > 0 && (
            <div>
              <ListItem button onClick={() => toggle('activities')}>
                <ListItemText primary="Activities" />
                {isOpen('activities') ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={isOpen('activities')} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {trip.activities.map((a, i) => (
                    <ListItem key={i} button className={classes.nested} onClick={() => onItemClick(a.id)}>
                      <ListItemText primary={a.name} />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </div>
          )}

          {trip.transport.length > 0 && (
            <div>
              <ListItem button onClick={() => toggle('transport')}>
                <ListItemText primary="Transport" />
                {isOpen('transport') ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={isOpen('transport')} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {trip.transport.map((a, i) => (
                    <ListItem key={i} button className={classes.nested} onClick={() => onItemClick(a.id)}>
                      <ListItemText primary={`${a.from.name} - ${a.to.name}`} />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </div>
          )}

          {trip.carRentals.length > 0 && (
            <div>
              <ListItem button onClick={() => toggle('car-rental')}>
                <ListItemText primary="Car Rental" />
                {isOpen('car-rental') ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={isOpen('car-rental')} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {trip.carRentals.map((a, i) => (
                    <ListItem key={i} button className={classes.nested} onClick={() => onItemClick(a.id)}>
                      <ListItemText primary={`${a.from.name} - ${a.to.name}`} />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </div>
          )}
        </List>
      </Grid>
    </Grid>
  )
}

export default TripItemsList
