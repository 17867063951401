import React, { useReducer, useState } from 'react'
import { Transport, TransportType, Email } from '../Api'
import TripItem from './TripItem'
import { makeStyles } from '@material-ui/core/styles'
import { FaPlane, FaTrain, FaBus, FaCar } from 'react-icons/fa'
import { LocalDateTime, LocalDate, DateTimeFormatter } from 'js-joda'
import * as TransportForm from '../trip-forms/TransportForm'
import { Button, Grid } from '@material-ui/core'

const formatTime: (dateTime: LocalDateTime) => string = dateTime =>
  dateTime.format(DateTimeFormatter.ofPattern('HH:mm'))

const useStyles = makeStyles(theme => ({
  icon: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
  },
}))

const TransportItem: React.FC<{
  minDate: LocalDate
  maxDate: LocalDate
  transport: Transport
  returnTransport?: Transport
  onUpdate: (_: Transport[]) => void
  tripId: string
  emails: Email[]
}> = ({ minDate, maxDate, transport, returnTransport, onUpdate, tripId, emails }) => {
  const classes = useStyles()

  const transportIcon = (transportType: TransportType) => {
    switch (transportType) {
      case 'flight':
        return <FaPlane className={classes.icon} />
      case 'train':
        return <FaTrain className={classes.icon} />
      case 'bus':
        return <FaBus className={classes.icon} />
      case 'car':
        return <FaCar className={classes.icon} />
    }
  }

  const [editing, setEditing] = useState<boolean>(false)
  const [transportFormState, transportFormDispatch] = useReducer(
    TransportForm.reducer,
    TransportForm.initialState(minDate, maxDate, [transport].concat(returnTransport ? [returnTransport] : [])),
  )

  const editView = editing ? (
    <>
      <Grid container spacing={3} direction="column" justify="flex-start" alignItems="stretch">
        <Grid item>
          <TransportForm.TransportForm state={transportFormState} dispatch={transportFormDispatch} />
        </Grid>
        <Grid container item spacing={3}>
          <Grid item>
            <Button variant="contained" onClick={() => onUpdate(TransportForm.toTransport(transportFormState))}>
              Save
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={() => setEditing(false)}>Cancel</Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  ) : (
    undefined
  )

  return (
    <TripItem
      icon={transportIcon(transport.type)}
      label={`${formatTime(LocalDateTime.parse(transport.from.datetime))} ${transport.from.name} - ${formatTime(
        LocalDateTime.parse(transport.to.datetime),
      )} ${transport.to.name}`}
      tripId={tripId}
      emails={emails}
      editable={true}
      onEditToggle={() => setEditing(!editing)}
      editView={editView}
    />
  )
}

export default TransportItem
