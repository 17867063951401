import React, { useReducer, useState } from 'react'
import * as ActivityForm from './trip-forms/ActivityForm'
import { Activity, CarRental, Transport, Accommodation, Trip, Email } from './Api'
import TransportItem from './trip-items/TransportItem'
import AccommodationItem from './trip-items/AccommodationItem'
import ActivityItem from './trip-items/ActivityItem'
import CarRentalItem from './trip-items/CarRentalItem'
import { LocalDate, LocalDateTime, DateTimeFormatter } from 'js-joda'
import { makeStyles } from '@material-ui/core/styles'
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  CardHeader,
  IconButton,
  Menu,
  MenuItem,
  List,
} from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'

const format: (dateTime: LocalDate) => string = dateTime => dateTime.format(DateTimeFormatter.ofPattern('yyyy-MM-dd'))

const useStyles = makeStyles(theme => ({
  cardContent: {
    padding: theme.spacing(2),
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  gridContainer: {
    display: 'flex',
  },
  emailIcon: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    cursor: 'pointer',
  },
}))

export interface Day {
  tripId: string
  date: LocalDate
  minDate: LocalDate
  maxDate: LocalDate
  activities: Activity[]
  transport: {
    main: Transport
    return?: Transport
  }[]
  carRentalPickUp?: CarRental
  carRental?: CarRental
  carRentalDropOff?: CarRental
  checkOutAccommodation?: Accommodation
  checkInAccommodation?: Accommodation
  sleepingAt?: Accommodation
  emails: Email[]
}

export const DayCard: React.FC<{
  day: Day
  onActivityAdded: (_: Activity) => void
  onTransportUpdate: (_: Transport[]) => void
  onCarRentalUpdate: (_: CarRental) => void
  onActivityUpdate: (_: Activity) => void
  onAccommodationUpdate: (_: Accommodation) => void
}> = ({ day, onActivityAdded, onTransportUpdate, onCarRentalUpdate, onActivityUpdate, onAccommodationUpdate }) => {
  const classes = useStyles()
  const [addActivityShown, setAddActivityShown] = useState(false)

  const [activityFormState, activityFormDispatch] = useReducer(
    ActivityForm.reducer,
    ActivityForm.initialStateWithDate(day.date, day.date, day.date),
  )

  const addActivityForm = addActivityShown ? (
    <Grid container item spacing={3} direction="column" justify="flex-start" alignItems="stretch">
      <Grid item>
        <ActivityForm.ActivityForm state={activityFormState} dispatch={activityFormDispatch} showDate={false} />
      </Grid>
      <Grid container item spacing={3}>
        <Grid item>
          <Button
            variant="contained"
            onClick={() => {
              onActivityAdded(ActivityForm.toActivity(activityFormState))
              activityFormDispatch(ActivityForm.resetAction)
              setAddActivityShown(false)
            }}
          >
            Add
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={() => {
              activityFormDispatch(ActivityForm.resetAction)
              setAddActivityShown(false)
            }}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Grid>
  ) : null

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  function handleClick(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  const addActivity = () => {
    setAddActivityShown(true)
    setAnchorEl(null)
  }

  const toEmails = (emailIds: string[]) => emailIds.flatMap(id => day.emails.filter(e => e.id === id))

  return (
    <Card>
      <CardHeader
        action={
          <div>
            <IconButton onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
            <Menu anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
              <MenuItem onClick={addActivity}>Add activity</MenuItem>
            </Menu>
          </div>
        }
        title={format(day.date)}
        titleTypographyProps={{
          variant: 'h6',
        }}
      />
      <CardContent className={classes.cardContent}>
        <Grid container spacing={3} direction="column" justify="flex-start" alignItems="stretch">
          {day.checkOutAccommodation && (
            <Grid item>
              <Typography variant="body1">Check Out:</Typography>
              <List>
                <AccommodationItem
                  type={'check-out'}
                  accommodation={day.checkOutAccommodation}
                  tripId={day.tripId}
                  emails={toEmails(day.checkOutAccommodation.emails)}
                  minDate={day.minDate}
                  maxDate={day.maxDate}
                  onUpdate={onAccommodationUpdate}
                />
              </List>
            </Grid>
          )}
          {day.checkInAccommodation && (
            <Grid item>
              <Typography variant="body1">Check In:</Typography>
              <List>
                <AccommodationItem
                  type={'check-in'}
                  accommodation={day.checkInAccommodation}
                  tripId={day.tripId}
                  emails={toEmails(day.checkInAccommodation.emails)}
                  minDate={day.minDate}
                  maxDate={day.maxDate}
                  onUpdate={onAccommodationUpdate}
                />
              </List>
            </Grid>
          )}
          {day.carRental && !(day.carRentalPickUp || day.carRentalDropOff) && (
            <Grid item>
              <List>
                <CarRentalItem
                  carRental={day.carRental}
                  tripId={day.tripId}
                  emails={toEmails(day.carRental.emails)}
                  minDate={day.minDate}
                  maxDate={day.maxDate}
                  onUpdate={onCarRentalUpdate}
                />
              </List>
            </Grid>
          )}
          {day.carRentalPickUp && (
            <Grid item>
              <List>
                <CarRentalItem
                  type={'pick-up'}
                  carRental={day.carRentalPickUp}
                  tripId={day.tripId}
                  emails={toEmails(day.carRentalPickUp.emails)}
                  minDate={day.minDate}
                  maxDate={day.maxDate}
                  onUpdate={onCarRentalUpdate}
                />
              </List>
            </Grid>
          )}
          {day.carRentalDropOff && (
            <Grid item>
              <List>
                <CarRentalItem
                  type={'drop-off'}
                  carRental={day.carRentalDropOff}
                  tripId={day.tripId}
                  emails={toEmails(day.carRentalDropOff.emails)}
                  minDate={day.minDate}
                  maxDate={day.maxDate}
                  onUpdate={onCarRentalUpdate}
                />
              </List>
            </Grid>
          )}
          {day.sleepingAt && (
            <Grid item>
              <Typography variant="body1">Staying at:</Typography>
              <List>
                <AccommodationItem
                  type={'sleeping'}
                  accommodation={day.sleepingAt}
                  tripId={day.tripId}
                  emails={toEmails(day.sleepingAt.emails)}
                  minDate={day.minDate}
                  maxDate={day.maxDate}
                  onUpdate={onAccommodationUpdate}
                />
              </List>
            </Grid>
          )}
          {day.transport.length > 0 && (
            <Grid item>
              <Typography variant="body1">Transport</Typography>
              <List>
                {day.transport.map((t, i) => (
                  <TransportItem
                    minDate={day.minDate}
                    maxDate={day.maxDate}
                    transport={t.main}
                    tripId={day.tripId}
                    returnTransport={t.return}
                    onUpdate={onTransportUpdate}
                    emails={toEmails(t.main.emails)}
                    key={i}
                  />
                ))}
              </List>
            </Grid>
          )}
          {day.activities.length > 0 && (
            <Grid item>
              <Typography variant="body1">Activities</Typography>
              <List>
                {day.activities.map((a, i) => (
                  <ActivityItem
                    activity={a}
                    tripId={day.tripId}
                    emails={toEmails(a.emails)}
                    key={i}
                    minDate={day.minDate}
                    maxDate={day.maxDate}
                    onUpdate={onActivityUpdate}
                  />
                ))}
              </List>
            </Grid>
          )}
          {addActivityForm}
        </Grid>
      </CardContent>
    </Card>
  )
}

export const dateToDay: (tripId: string, trip: Trip, emails: Email[]) => (date: LocalDate) => Day = (
  tripId,
  trip,
  emails,
) => date => {
  const minDate = LocalDate.parse(trip.startDate)
  const maxDate = LocalDate.parse(trip.endDate)
  const dayStart = LocalDateTime.of(date.year(), date.monthValue(), date.dayOfMonth(), 0, 0, 0)
  const nextDay = dayStart.plusDays(1)

  return {
    tripId,
    date,
    minDate,
    maxDate,
    checkInAccommodation: trip.accommodations.find(a => {
      const aDate = LocalDateTime.parse(a.start)
      return aDate.compareTo(dayStart) >= 0 && aDate.compareTo(nextDay) < 0
    }),
    checkOutAccommodation: trip.accommodations.find(a => {
      const aDate = LocalDateTime.parse(a.end)
      return aDate.compareTo(dayStart) >= 0 && aDate.compareTo(nextDay) < 0
    }),
    sleepingAt: trip.accommodations.find(a => {
      const start = LocalDateTime.parse(a.start)
      const end = LocalDateTime.parse(a.end)
      return start.compareTo(nextDay) < 0 && end.compareTo(nextDay) > 0
    }),
    carRentalPickUp: trip.carRentals.find(cr => {
      const aDate = LocalDateTime.parse(cr.from.datetime)
      return aDate.compareTo(dayStart) >= 0 && aDate.compareTo(nextDay) < 0
    }),
    carRental: trip.carRentals.find(cr => {
      const start = LocalDateTime.parse(cr.from.datetime)
      const end = LocalDateTime.parse(cr.to.datetime)
      return start.compareTo(nextDay) < 0 && end.compareTo(nextDay) > 0
    }),
    carRentalDropOff: trip.carRentals.find(cr => {
      const aDate = LocalDateTime.parse(cr.to.datetime)
      return aDate.compareTo(dayStart) >= 0 && aDate.compareTo(nextDay) < 0
    }),
    activities: trip.activities.filter(a => LocalDate.parse(a.date).compareTo(date) === 0),
    transport: trip.transport
      .filter(t => {
        const aDate = LocalDateTime.parse(t.from.datetime)
        return aDate.compareTo(dayStart) >= 0 && aDate.compareTo(nextDay) < 0
      })
      .map(t => ({
        main: t,
        return: trip.transport.find(rt => rt.return && rt.return === t.return && rt.id !== t.id),
      })),
    emails,
  }
}
