import React, { useState, useReducer } from 'react'
import { CarRental, Email } from '../Api'
import TripItem from './TripItem'
import { makeStyles } from '@material-ui/core/styles'
import { FaCar } from 'react-icons/fa'
import { LocalDateTime, LocalDate, DateTimeFormatter } from 'js-joda'
import * as CarRentalForm from '../trip-forms/CarRentalForm'
import { Button, Grid } from '@material-ui/core'

const formatTime: (dateTime: LocalDateTime) => string = dateTime =>
  dateTime.format(DateTimeFormatter.ofPattern('HH:mm'))

const useStyles = makeStyles(theme => ({
  icon: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
  },
}))

const CarRentalItem: React.FC<{
  minDate: LocalDate
  maxDate: LocalDate
  type?: 'pick-up' | 'drop-off'
  carRental: CarRental
  onUpdate: (_: CarRental) => void
  tripId: string
  emails: Email[]
}> = ({ minDate, maxDate, type, carRental, onUpdate, tripId, emails }) => {
  const classes = useStyles()

  const [editing, setEditing] = useState<boolean>(false)
  const [carFormState, carFormDispatch] = useReducer(
    CarRentalForm.reducer,
    CarRentalForm.initialState(minDate, maxDate, carRental),
  )

  const editView = editing ? (
    <>
      <Grid container spacing={3} direction="column" justify="flex-start" alignItems="stretch">
        <Grid item>
          <CarRentalForm.CarRentalForm state={carFormState} dispatch={carFormDispatch} />
        </Grid>
        <Grid container item spacing={3}>
          <Grid item>
            <Button variant="contained" onClick={() => onUpdate(CarRentalForm.toCarRental(carFormState))}>
              Save
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={() => setEditing(false)}>Cancel</Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  ) : (
    undefined
  )

  const label = () => {
    switch (type) {
      case 'pick-up':
        return `Pick Up: ${formatTime(LocalDateTime.parse(carRental.from.datetime))} ${carRental.from.name}`
      case 'drop-off':
        return `Drop Off: ${formatTime(LocalDateTime.parse(carRental.to.datetime))} ${carRental.to.name}`
      default:
        return `Have a car rental`
    }
  }

  return (
    <TripItem
      icon={<FaCar className={classes.icon} />}
      label={label()}
      tripId={tripId}
      emails={emails}
      editable={true}
      onEditToggle={() => setEditing(!editing)}
      editView={editView}
    />
  )
}

export default CarRentalItem
