import React, { useState } from 'react'
import { Trip, Email, TripAction } from './Api'
import { Day, dateToDay, DayCard } from './DayCard'
import EmailWithForms from './EmailWithForms'
import TripItemsList from './trip-items/TripItemsList'
import { LocalDate, Period } from 'js-joda'
import { Typography, Grid, Card, CardContent, CardHeader, Tabs, Tab } from '@material-ui/core'
import TripForms from './trip-forms/TripForms'
import EmailList from './EmailList'

const calculateDates = (start: LocalDate, end: LocalDate) => {
  const period = Period.between(start, end)
  return Array.from({ length: period.days() + 1 }, (x, i) => start.plusDays(i))
}

const TripDetails: React.FC<{
  tripDispatch: React.Dispatch<TripAction>
  tripId: string
  trip: Trip
  emails: Email[]
  setError: (e: string) => void
  isSaving: boolean
}> = ({ tripId, trip, emails, tripDispatch, setError, isSaving }) => {
  const minDate = LocalDate.parse(trip.startDate)
  const maxDate = LocalDate.parse(trip.endDate)

  const [emailShown, setEmailShown] = useState<Email | undefined>()
  const [selectedTab, setSelectedTab] = React.useState(0)
  function onTabSelected(_: React.ChangeEvent<{}>, newValue: number) {
    setSelectedTab(newValue)
  }

  const emailView = emailShown ? (
    <EmailWithForms
      tripId={tripId}
      trip={trip}
      email={emailShown}
      emails={emails}
      setError={setError}
      onTransportAdded={transport => tripDispatch({ type: 'add_transport', transport })}
      onCarRentalAdded={car => tripDispatch({ type: 'add_car_rental', car })}
      onAccommodationAdded={accommodation => tripDispatch({ type: 'add_accommodation', accommodation })}
      onActivityAdded={activity => tripDispatch({ type: 'add_day_activity', activity })}
      onItemLink={(itemId: string, emailId: string) => tripDispatch({ type: 'item_email_link', itemId, emailId })}
      minDate={minDate}
      maxDate={maxDate}
    />
  ) : null

  const days: Day[] = calculateDates(minDate, maxDate).map(dateToDay(tripId, trip, emails))

  const savingMessage = isSaving ? (
    <Grid item>
      <Typography variant="body1">Saving...</Typography>
    </Grid>
  ) : null

  return (
    <Grid container spacing={3} direction="row" justify="flex-start" alignItems="stretch">
      <Grid item xs={12}>
        <Tabs value={selectedTab} onChange={onTabSelected} indicatorColor="primary" textColor="primary" centered>
          <Tab label="Trip" />
          <Tab label="Emails" />
        </Tabs>
      </Grid>
      {selectedTab === 0 && (
        <Grid container item spacing={3} direction="column" justify="flex-start" alignItems="stretch">
          {days.map((day, i) => (
            <Grid key={i} item>
              <DayCard
                day={day}
                onActivityAdded={activity => tripDispatch({ type: 'add_day_activity', activity })}
                onTransportUpdate={transport => tripDispatch({ type: 'transport_update', transport })}
                onCarRentalUpdate={car => tripDispatch({ type: 'car_rental_update', car })}
                onActivityUpdate={activity => tripDispatch({ type: 'day_activity_update', activity })}
                onAccommodationUpdate={accommodation => tripDispatch({ type: 'accommodation_update', accommodation })}
              />
            </Grid>
          ))}
          <Grid item>
            <Card>
              <CardHeader
                title={trip.name}
                titleTypographyProps={{
                  variant: 'h5',
                }}
              />
              <CardContent>
                <Grid container item spacing={3} direction="column" justify="flex-start" alignItems="stretch">
                  <Grid item>
                    <TripItemsList trip={trip} emails={emails} onItemClick={() => null} />
                  </Grid>
                  <Grid item>
                    <TripForms
                      onTransportAdded={transport => tripDispatch({ type: 'add_transport', transport })}
                      onCarRentalAdded={car => tripDispatch({ type: 'add_car_rental', car })}
                      onAccommodationAdded={accommodation =>
                        tripDispatch({
                          type: 'add_accommodation',
                          accommodation,
                        })
                      }
                      onActivityAdded={() => {}}
                      minDate={minDate}
                      maxDate={maxDate}
                      hideActivity={true}
                    />
                  </Grid>
                  {savingMessage}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
      {selectedTab === 1 && (
        <Grid container item spacing={3} direction="column" alignItems="stretch" justify="flex-start">
          <Grid item>
            <EmailList
              tripId={tripId}
              trip={trip}
              emails={emails}
              onEmailSelected={emailId => setEmailShown(emails.find(email => email.id === emailId))}
              selectedEmailId={emailShown && emailShown.id}
            />
          </Grid>
          <Grid item>{emailView}</Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default TripDetails
