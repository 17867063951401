import React, { useReducer, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Trip, Email, getTrip, saveTrip, getTripEmails, TripAction } from './Api'
import { makeStyles } from '@material-ui/core/styles'
import { Container, Paper, Grid, LinearProgress } from '@material-ui/core'
import TripDetails from './TripDetails'
import { useAuth0 } from './react-auth0-wrapper'

const useAutosave = (tripId: string, trip: Trip | undefined) => {
  const [draftIsSaving, setDraftIsSaving] = useState(false)
  const [saveError, setSaveError] = useState()
  const [isLoaded, setIsLoaded] = useState(false)
  const { getAuthToken } = useAuth0()

  useEffect(() => {
    if (!isLoaded && trip) {
      setIsLoaded(true)
      return
    }
    const id = setTimeout(() => {
      if (!trip || draftIsSaving) {
        return
      }
      setDraftIsSaving(true)

      getAuthToken()
        .then(t => saveTrip(t, tripId, trip))
        .then(() => {
          setDraftIsSaving(false)
        })
        .catch(e => {
          setDraftIsSaving(false)
          setSaveError(`${e}`)
        })
    }, 500)
    return () => clearTimeout(id)

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [trip, tripId])

  return [draftIsSaving, saveError]
}

const useStyles = makeStyles(theme => ({
  main: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  container: {
    padding: theme.spacing(3),
  },
}))

const tripReducer: (trip: Trip | undefined, action: TripAction) => Trip | undefined = (trip, action) => {
  if (action.type === 'trip_loaded') {
    return action.trip
  }

  if (!trip) {
    return
  }

  switch (action.type) {
    case 'add_transport':
      return {
        ...trip,
        transport: trip.transport.concat(action.transport),
      }
    case 'transport_update':
      return {
        ...trip,
        transport: trip.transport.map(t => {
          const updated = action.transport.find(t1 => t1.id === t.id)
          return updated ? updated : t
        }),
      }
    case 'add_car_rental':
      return {
        ...trip,
        carRentals: trip.carRentals.concat([action.car]),
      }
    case 'car_rental_update':
      return {
        ...trip,
        carRentals: trip.carRentals.map(cr => (action.car.id === cr.id ? action.car : cr)),
      }
    case 'add_accommodation':
      return {
        ...trip,
        accommodations: trip.accommodations.concat([action.accommodation]),
      }
    case 'accommodation_update':
      return {
        ...trip,
        accommodations: trip.accommodations.map(a => (action.accommodation.id === a.id ? action.accommodation : a)),
      }
    case 'add_day_activity':
      return {
        ...trip,
        activities: trip.activities.concat([action.activity]),
      }
    case 'day_activity_update':
      return {
        ...trip,
        activities: trip.activities.map(a => (action.activity.id === a.id ? action.activity : a)),
      }
    case 'item_email_link':
      return {
        ...trip,
        activities: trip.activities.map(i => {
          if (i.id === action.itemId) {
            return { ...i, emails: i.emails.filter(eid => eid !== action.emailId).concat([action.emailId]) }
          }
          return i
        }),
        accommodations: trip.accommodations.map(i => {
          if (i.id === action.itemId) {
            return { ...i, emails: i.emails.filter(eid => eid !== action.emailId).concat([action.emailId]) }
          }
          return i
        }),
        transport: trip.transport.map(i => {
          if (i.id === action.itemId) {
            return { ...i, emails: i.emails.filter(eid => eid !== action.emailId).concat([action.emailId]) }
          }
          return i
        }),
        carRentals: trip.carRentals.map(i => {
          if (i.id === action.itemId) {
            return { ...i, emails: i.emails.filter(eid => eid !== action.emailId).concat([action.emailId]) }
          }
          return i
        }),
      }
    default:
      throw new Error()
  }
}

interface TripViewRouteParams {
  tripId: string
}

interface TripViewProps extends RouteComponentProps<TripViewRouteParams>, React.Props<TripViewRouteParams> {}

const TripView: React.FC<TripViewProps> = ({ match }) => {
  const tripId = match.params.tripId
  const { getAuthToken } = useAuth0()
  const classes = useStyles()
  const [trip, tripDispatch] = useReducer(tripReducer, undefined)

  const [isSaving, saveError] = useAutosave(tripId, trip)
  const [emails, setEmails] = useState<Email[]>([])

  const [error, setError] = useState<string | undefined>(undefined)

  useEffect(() => {
    getAuthToken()
      .then(t => getTrip(t, tripId))
      .then(trip => {
        tripDispatch({ type: 'trip_loaded', trip })
      })
      .catch(e => setError(`${e}`))

    getAuthToken()
      .then(t => getTripEmails(t, tripId))
      .then(emails => {
        setEmails(emails)
      })
      .catch(e => setError(`${e}`))
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])

  const errorView = error ? <div>{error}</div> : null
  const saveErrorView = saveError ? <div>saveError</div> : null

  const tripView = trip ? (
    <TripDetails
      tripDispatch={tripDispatch}
      tripId={tripId}
      trip={trip}
      emails={emails}
      setError={setError}
      isSaving={isSaving && !saveError}
    />
  ) : (
    <Grid container spacing={3} direction="column" justify="flex-start" alignItems="stretch">
      <Grid item>
        <Paper className={classes.container}>
          <LinearProgress />
        </Paper>
      </Grid>
    </Grid>
  )

  return (
    <Container className={classes.main} maxWidth="xl">
      <Grid container spacing={3}>
        {errorView}
        {saveErrorView}
        {tripView}
      </Grid>
    </Container>
  )
}

export default TripView
