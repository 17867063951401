import React, { useReducer } from 'react'
import { LocalDate, LocalDateTime, DateTimeFormatter } from 'js-joda'
import { Trip, createTrip } from './Api'
import { Redirect } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Container, TextField, Card, CardContent, LinearProgress, Grid, Button, Paper } from '@material-ui/core'
import DateTimePicker from './DateTimePicker'
import { useAuth0 } from './react-auth0-wrapper'

const format: (dateTime: LocalDate) => string = dateTime => dateTime.format(DateTimeFormatter.ofPattern('yyyy-MM-dd'))

interface State {
  start: LocalDateTime
  end: LocalDateTime
  name: string
  creating: boolean
  tripId?: string
  error?: string
}

const initialState: State = {
  start: LocalDateTime.now(),
  end: LocalDateTime.now(),
  creating: false,
  name: '',
}

function generateTrip(state: State): Trip {
  return {
    name: state.name,
    startDate: format(state.start.toLocalDate()),
    endDate: format(state.end.toLocalDate()),
    transport: [],
    accommodations: [],
    carRentals: [],
    activities: [],
  }
}

type Action =
  | {
      type: 'set_start'
      date: LocalDateTime
    }
  | {
      type: 'set_end'
      date: LocalDateTime
    }
  | {
      type: 'set_name'
      name: string
    }
  | {
      type: 'create_start'
    }
  | {
      type: 'trip_created'
      tripId: string
    }
  | {
      type: 'set_error'
      error: string
    }

const reducer: (state: State, action: Action) => State = (state, action) => {
  switch (action.type) {
    case 'set_start':
      return {
        ...state,
        start: action.date,
      }
    case 'set_end':
      return {
        ...state,
        end: action.date,
      }
    case 'set_name':
      return { ...state, name: action.name }
    case 'create_start':
      return { ...state, creating: true }
    case 'trip_created':
      return { ...state, creating: false, tripId: action.tripId }
    case 'set_error':
      return { ...state, error: action.error }
    default:
      throw new Error()
  }
}

const useStyles = makeStyles(theme => ({
  main: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  container: {
    padding: theme.spacing(3),
  },
}))

const CreateTrip: React.FC = () => {
  const classes = useStyles()
  const [state, dispatch] = useReducer(reducer, initialState)
  const { getAuthToken } = useAuth0()

  const error = state.error ? <div>{state.error}</div> : null

  if (state.tripId !== undefined) {
    return <Redirect to={`/trip/${state.tripId}`} />
  }

  const onCreateTrip = () => {
    dispatch({ type: 'create_start' })
    getAuthToken()
      .then(t => createTrip(t, generateTrip(state)))
      .then(tripCreated => {
        dispatch({ type: 'trip_created', tripId: tripCreated.tripId })
      })
      .catch(e => {
        dispatch({ type: 'set_error', error: `${e}` })
      })
  }

  const form = (
    <Grid item xs={12} sm={8} md={6} lg={5}>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <DateTimePicker
                dateTime={state.start}
                min={LocalDate.now()}
                onChange={dateTime => {
                  dispatch({ type: 'set_start', date: dateTime })
                }}
                showTime={false}
              />
            </Grid>
            <Grid item xs={6}>
              <DateTimePicker
                dateTime={state.end}
                min={state.start.toLocalDate()}
                onChange={dateTime => {
                  dispatch({ type: 'set_end', date: dateTime })
                }}
                showTime={false}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="normal"
                value={state.name}
                onChange={e => dispatch({ type: 'set_name', name: e.target.value })}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" fullWidth variant="contained" color="primary" onClick={onCreateTrip}>
                Create Trip
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  )

  const loader = state.creating && (
    <Grid item xs={12}>
      <Paper className={classes.container}>
        <LinearProgress />
      </Paper>
    </Grid>
  )

  return (
    <Container className={classes.main} maxWidth="lg">
      <Grid container spacing={3} justify="center">
        <Grid item xs={12}>
          {error}
        </Grid>
        {!state.creating && form}
        {loader}
      </Grid>
    </Container>
  )
}

export default CreateTrip
